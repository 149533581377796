import React from 'react';
import "./Cart.css";
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection';
import CartListSec from '../../components/cartListSec/CartListSec';
import BottomSlider from '../../components/bottomSlider/BottomSlider';

const Cart = () => {
  return (
    <>
      <PagesHeroSection title="Cart" content="cart" />
      <CartListSec />
      <BottomSlider/>
    </>
  );
};

export default Cart;
