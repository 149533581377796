import React from 'react'
import './footer.css'
import footerLogo from '../../assets/images/footer-logo.png'
import { Link, NavLink } from 'react-router-dom'
import { scrollToTop } from '../../functions/ScrollToTop'
export default function Footer() {
    return (
        <div className='footerContainer'>
            <div className="footerSec">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 col-md-4 col-sm-12 px-2">
                            <div className="footer__Box">
                                <div className="footer__logo">
                                    <img className='img-fluid' src={footerLogo} alt="" />
                                </div>
                                <div className="footer__item__social my-2">
                                    <ul className='d-flex just justify-content-start'>
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=61552852431662" target='blank'>
                                                <i className="bi bi-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/vilchesprofissional?igshid=NzZhOTFlYzFmZQ==" target='blank'>
                                                <i className="bi bi-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://youtube.com/@vilchesprofissional?si=erhTg7mPIZOLRERi" target='blank'>
                                                <i className="bi bi-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 px-2">
                            <div className="footer__Box pt-2">
                                <div className="footer__Box__title">
                                    <h5>
                                        CONTACT
                                    </h5>
                                </div>
                                <div className="footer__Box__info">
                                    <p className='contact__placeholder d-flex'>
                                        <i className="bi bi-geo-alt"></i>
                                        <span>
                                            <a href="">
                                                Rua Beethoven 401 jardim arco íris diadema São Paulo Brasil
                                            </a>
                                        </span>
                                    </p>
                                    <p className='contact__placeholder d-flex'>
                                        <i className="bi bi-phone"></i>
                                        <span>
                                        <a href="tel:+55 11 91253-2459">+55 11 91253-2459</a>
                                        </span>
                                    </p>
                                    <p className='contact__placeholder d-flex'>
                                        <i className="bi bi-envelope"></i>
                                        <span>
                                            <a href="mailto:vilchesprofissional@gmail.com">vilchesprofissional@gmail.com</a>
                                        </span>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 px-2">
                            <div className="footer__Box pt-2">
                                <div className="footer__Box__title">
                                    <h5>
                                        support
                                    </h5>
                                </div>
                                <div className="footer__Box__info">
                                    <p>
                                        <NavLink onClick={()=>scrollToTop()} className="nav-link" to="/about">
                                            About
                                        </NavLink>
                                    </p>
                                    <p>
                                        <NavLink onClick={()=>scrollToTop()} className="nav-link" to="/faq">
                                            Faq
                                        </NavLink>
                                    </p>
                                    <p>
                                        <NavLink onClick={()=>scrollToTop()} className="nav-link" to="/Privacy">
                                            Privacy Policy
                                        </NavLink>
                                    </p>
                                    <p>
                                        <NavLink onClick={()=>scrollToTop()} className="nav-link" to="/contact">
                                            contact
                                        </NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerBottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <p>
                                © 2023
                                <Link className='footerLink' to={"https://www.facebook.com/TrioAdv1"} target="_blank">
                                    Trio
                                </Link> , All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
