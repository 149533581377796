export const addToLocalStorage = (name,token)=>{
    localStorage.setItem(name,token);
};

export const getFromLocalStorage = (name)=>{
    return localStorage.getItem(name);
};

export const removeFromLocalStorage = (name)=>{
    localStorage.removeItem(name);
};