import React from 'react';
import "./PagesHeroSection.css";
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../../functions/ScrollToTop';

const PagesHeroSection = ({title,content}) => {
  return (
    <div className='allPages__heroSection'>
      <h2 className='allPages__heroSection__title'>{title}</h2>
      <p className='allPages__heroSection__content'><NavLink onClick={()=>scrollToTop()} to="/" className="nav-link text-light d-inline">Home</NavLink> / {content}</p>
    </div>
  );
};

export default PagesHeroSection;