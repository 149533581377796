import React from 'react';
import "./CartListSec.css";
import CartList from '../cartList/CartList';
import CartCheckoutPart from '../cartSideCheckOut/CartCheckoutPart';


const CartListSec = () => {
  return (
    <div className='cartList__Sec'>
      <div className="container">
        <div className="row">
          <CartList/>
          <CartCheckoutPart />
        </div>
      </div>
    </div>
  );
};

export default CartListSec;
