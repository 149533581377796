import React from 'react'
import './benfitDesc.css'
import prodItem from '../../assets/images/prodImg/prod3.png'
import prodItemBack from '../../assets/images/prodImg/prod3-back.png'

export default function BenfitDesc() {
    return (
        <div className='benfitDescSec'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="benfitDesc__text">
                            <h1>
                                benfits
                            </h1>
                            <h4>
                                Strengthening Hair
                            </h4>
                            <p>
                                VILCHES brings a Unique care Experience, our goal is to make your hair more attractive and healthy, using high-tech cosmetics associated with the best natural assets, we present a new concept to the cosmetic industry market that combines the use of modern technology.
                            </p>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex   align-items-center orderOnSmallScreen">
                        <div className="benfitDesc__img ">
                            <img className='w-100' src={prodItem} alt=""
                                onMouseOver={e => (e.currentTarget.src = prodItemBack)}
                                onMouseOut={e => (e.currentTarget.src = prodItem)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
