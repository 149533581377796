import React from 'react';
import "./ContactMap.css";

const ContactMap = () => {
  return (
    <div className='container'>
      <iframe
      className='w-100' style={{height: "420px",marginBlock: "80px"}}
      src="https://maps.google.com/maps?q=Brazil&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed">
      </iframe>
    </div>
  );
};

export default ContactMap;
