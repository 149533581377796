import * as yup from "yup";

export const RegisterSchema = yup.object().shape({
    "first_name": yup
    .string(),
    "last_name": yup
    .string(),
    "address": yup
    .string(),
    "zip_code": yup 
    .string(),
    "email": yup
    .string(),
    "password": yup
    .string(),
    "password_confirmation": yup
    .string(),
});