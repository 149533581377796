import { Route, Routes } from 'react-router-dom';
import './App.css';
import MyNav from './components/myNav/MyNav';
import MyHome from './pages/homePage/MyHome';
import Footer from './components/footer/Footer';
import { useState } from 'react';
import ShopPage from './pages/shopPage/ShopPage';
import AboutUs from './pages/about__Us/AboutUs';
import Faqs from './pages/faqs/Faqs';
import ContactUs from './pages/contact/ContactUs';
import Cart from './pages/cart/Cart';
import CartCanvas from './components/cartCanvas/CartCanvas';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import ErrorPage from './pages/error_Page/ErrorPage';
import SingleProduct from './pages/singleProductDetail/SingleProduct';
import Privacy from './pages/privacyAndPolicy/Privacy';
import ScrollToTopButton from './components/scrollToTopButton/ScrollToTopButton';

function App() {
  const [scrollToggle, setScrollToggle] = useState(false);
  const currentRoute = window.location.pathname;

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setScrollToggle(true);
    } else {
      setScrollToggle(false);
    }
  });

  return (
    <>

      {
        !(
          currentRoute.toLowerCase() === "/Login".toLowerCase()
          ||
          (currentRoute.toLowerCase() === "/Register".toLowerCase())
        ) &&
        <>
          <MyNav scrollToggle={scrollToggle} />
          <ScrollToTopButton/>
          <CartCanvas />
        </>
      }

      <Routes>

        <Route
          path='/'
          element={
            <MyHome />
          }
        />

        <Route
          path='/Shop'
          element={
            <ShopPage />
          }
        />
        <Route
          path='/shop/:name'
          element={
            <SingleProduct />
          }
        />
        <Route
          path='/About'
          element={
            <AboutUs />
          }
        />

        <Route
          path='/Faq'
          element={
            <Faqs />
          }
        />

        <Route
          path='/Privacy'
          element={
            <Privacy />
          }
        />

        <Route
          path='/Contact'
          element={
            <ContactUs />
          }
        />

        <Route
          path='/Cart'
          element={
            <Cart />
          }
        />

        <Route
          path='/Login'
          element={
            <Login />
          }
        />

        <Route
          path='/Register'
          element={
            <Register />
          }
        />


        <Route
          path='*'
          element={
            <ErrorPage />
          }
        />

      </Routes>

      {
        !(
          (currentRoute.toLowerCase() === "/Login".toLowerCase())
          ||
          (currentRoute.toLowerCase() === "/Register".toLowerCase())
        ) &&
        <Footer />
      }

    </>
  );
};

export default App;
