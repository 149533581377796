import React, { useEffect, useState } from 'react';
import "./Register.css";
import { Col, Container, Row } from 'react-bootstrap';
import { RegisterSchema } from '../../validation/RegisterSchema';
import { useFormik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { URL } from '../../functions/URLSTORE';
import { scrollToTop } from '../../functions/ScrollToTop';

const Register = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [backEndErrors, setBackEndErrors] = useState(null);
  const [countries, setCountries] = useState([]);
  const [currCities, setCurrCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [city_id, setCity_id] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [phone, setPhone] = useState("");

  const onSubmit = async (values, actions) => {
    const isValid = await RegisterSchema.validate(values);
    if (isValid) {
      const res = await fetch(`${URL}/register`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          ...values,
          "city_id": city_id,
          "phone": phone
        })
      });
      const response = await res.json();
      if (response.status) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1000
        });
        actions.resetForm();
        setPhone("");
        setCity_id("");
        setCurrentCity([]);
        setSelectedCountry([]);
        setShowErrors(false);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!'
        });
        setBackEndErrors({ ...response.errors });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      address: "",
      zip_code: "",
      email: "",
      password: "",
      password_confirmation: ""
    },
    validationSchema: RegisterSchema,
    onSubmit,
  });

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    (async () => {
      const fetchedData = await fetch(`${URL}/countries`);
      const res = await fetchedData.json();
      setCountries(res.data);
    })();
  }, [values.country]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);

    const currCountryObj = countries.find(country => country?.name === e.target.value);
    const id = currCountryObj?.id;
    (async () => {
      const fetchedData = await fetch(`${URL}/countries/${id}/cities`);
      const res = await fetchedData.json();
      setCurrCities(res.data.cities);
    })();
  };

  const handleChangeCity = (e) => {
    setCurrentCity(e.target.value);

    const currentCityObj = currCities.find(city => city?.name === e.target.value);
    setCity_id(currentCityObj?.id);
    console.log(city_id)
  };

  const handleChangeInputValue = (e) => {
    setPhone(e.target.value);
  };

  return (
    <>
      {
        loading ?
          <div className="loaderContainer">
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loader loader-inner"></div>
              </div>
            </div>
          </div>
          :
          <div className="registerAndLoginPage position-relative">
            <div className="icon__routeBackToCart position-absolute" onClick={() => {
              (async () => {
                await navigate("/cart");
                window.scrollTo({
                  top: 210,
                });
              })()
            }}>
              <i className="bi bi-box-arrow-left fs-1"></i>
            </div>
            <Container className='position-relative'>
              <Row>
                <Col lg={10} className='mx-auto registeration__form'>
                  <h2 className='text-center'>Register</h2>
                  <form
                    action="Post"
                    className='px-5 pt-3 row gapY-3'
                    onSubmit={handleSubmit}
                    onBlur={handleBlur}
                  >

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <input
                        value={values.first_name}
                        onChange={handleChange}
                        type="text"
                        id='first_name'
                        name='first_name'
                        placeholder='First Name'
                        className={`first_name__input w-100 ${((errors.first_name && touched.first_name) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.first_name || backEndErrors?.first_name}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <input
                        value={values.last_name}
                        onChange={handleChange}
                        type="text"
                        id='last_name'
                        name='last_name'
                        placeholder='Last Name'
                        className={`last_name__input w-100 ${((errors.last_name && touched.last_name) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.last_name || backEndErrors?.last_name}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className={`form-select country__select w-100 ${(backEndErrors) ? "input-error" : ""}`}
                        aria-label="Default select example"
                        id='registeration__form__country'
                        name='country'
                      >
                        <option defaultChecked>Select Your Country</option>
                        {countries?.map(country => {
                          return (
                            <option key={country?.id} value={country?.name}>
                              {country?.name}
                            </option>
                          )
                        })}
                      </select>
                      {showErrors && <p className='text-danger text-capitalize'>Required</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <select
                        value={currentCity}
                        onChange={handleChangeCity}
                        className={`form-select city__select w-100 ${(backEndErrors) ? "input-error" : ""}`}
                        aria-label="Default select example"
                        id='registeration__form__city'
                        name='city'
                      >
                        <option defaultChecked>Select Your City</option>
                        {currCities?.map(city => {
                          return (
                            <option
                              key={city?.id}
                              value={city?.name}>{city?.name}</option>
                          )
                        })}
                      </select>
                      {showErrors && <p className='text-danger text-capitalize'>{errors.city || backEndErrors?.city}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-12`}>
                      <input
                        value={values.address}
                        onChange={handleChange}
                        type="text"
                        id='address'
                        name='address'
                        placeholder='Address'
                        className={`registeration__form__address w-100 ${((errors.address && touched.address) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.address || backEndErrors?.address}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <input
                        value={phone}
                        onChange={handleChangeInputValue}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder='Phone Number'
                        className={`registeration__form__phone w-100 ${(backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{backEndErrors?.phone}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <input
                        value={values.zip_code}
                        onChange={handleChange}
                        type="number"
                        name="zip_code"
                        id="zip_code"
                        placeholder='Zip Code'
                        className={`registeration__form__zip_code w-100 ${((errors.zip_code && touched.zip_code) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.zip_code || backEndErrors?.zip_code}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-12`}>
                      <input
                        value={values.email.trim()}
                        onChange={handleChange}
                        type="email"
                        name="email"
                        id="email"
                        placeholder='Email'
                        className={`registeration__form__email w-100 ${((errors.email && touched.email) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.email || backEndErrors?.email}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <input
                        value={values.password.trim()}
                        onChange={handleChange}
                        type="password"
                        name="password"
                        id="password"
                        placeholder='Password'
                        className={`registeration__form__password w-100 ${((errors.password && touched.password) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.password || backEndErrors?.password}</p>}
                    </div>

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-md-6`}>
                      <input
                        value={values.password_confirmation.trim()}
                        onChange={handleChange}
                        type="password"
                        name="password_confirmation"
                        id="password_confirmation"
                        placeholder='Confirm Password'
                        className={`registeration__form__password_confirmation w-100 ${((errors.password_confirmation && touched.password_confirmation) || backEndErrors) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='text-danger text-capitalize'>{errors.password_confirmation || backEndErrors?.password_confirmation}</p>}
                    </div>



                    <div className="registeration__form__btn d-flex justify-content-end">
                      <button type='submit' onClick={() => setShowErrors(true)}
                      >Register</button>
                    </div>

                    {/* <div className="registeration__form__btn__google d-flex justify-content-center align-items-center my-3 w-100" onClick={()=>{
                      window.location.href = "http://backend.vilchesprofessional.com/redirect/google";
                      }}>
                        <div className="icon__handler">
                          <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt='google-Icon'/>
                        </div>
                          <span>
                          sign up with google
                          </span>
                      </div> */}

                    <div className="col-12 text-light">
                      <span>Already have an account? </span>
                      <NavLink to="/Login" className="gotoLoginLink ms-2" onClick={()=> scrollToTop()}>
                        Login now
                      </NavLink>
                    </div>

                  </form>
                </Col>
              </Row>
            </Container>
          </div>
      }
    </>
  );
};

export default Register;