import React from 'react'
import './heroSlider.css'
import heroImgOne from '../../assets/images/new-banner.jpg'
import heroImgtwo from '../../assets/images/new.jpg'
export default function HeroSlider() {
    return (
        <div className=''>
            <div className="hero__img__banner">
                <img src={heroImgOne} className='w-100' alt="" />
            </div>
            <div className="hero__img__banner__phone">
                <img src={heroImgtwo} className='img-fluid' alt="" />
            </div>
        </div>
    )
}
