import React from 'react';
import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import "./BottomSlider.css";

import sliderImg1 from "../../assets/images/bottomSlider/brand-logo1.png";
import sliderImg3 from "../../assets/images/bottomSlider/brand-logo3.png";

const BottomSlider = () => {
    return (
        <div className='bottom__slider'>
            <div className="container">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    loop={false}
                    className='mySlider slider__large__screen'
                    >
                    <SwiperSlide className='text-center'><img src={sliderImg1} alt="sliderImg1" /></SwiperSlide>
                    <SwiperSlide className='text-center'><img src={sliderImg3} alt="sliderImg3" /></SwiperSlide>
                </Swiper>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={false}
                    className='mySlider slider__small__screen'
                    >
                    <SwiperSlide className='text-center'><img src={sliderImg1} alt="sliderImg1" /></SwiperSlide>
                    <SwiperSlide className='text-center'><img src={sliderImg3} alt="sliderImg3" /></SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default BottomSlider;
