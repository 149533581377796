import React from 'react';
import "./Faqs.css";
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection';
import MyFaq from '../../components/myFaq/MyFaq';

const Faqs = () => {
  return (
    <>
      <PagesHeroSection title="Faqs" content="faqs" />
      <MyFaq/>
    </>
  )
}

export default Faqs
