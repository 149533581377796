import React from 'react'
import './benfitHome.css'

export default function BenfitHome({ imgs, backGround }) {
    return (
        <div className='benfit__sec position-relative'>
            <div className="benfit__sec__backGround__Image">
                <img src={backGround} className='w-100' alt="benfit BackGround" />
            </div>
            <div className="container">
                <div className="benfit__text">
                    <h4 className='benfit__text__title position-relative'>
                        Benefits
                    </h4>
                </div>
                <div className="benfit__points__sec">
                    <div className="row row__item__one justify-content-between">
                        <div className="col-lg-5 col-md-5 d-flex  align-items-center box__item  box__item__left0">
                            <div className="point__text">
                                <p>
                                    Straightens hair 100%.
                                </p>
                            </div>
                            <div className="point__img">
                                <img className="" src={imgs[0]} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 d-flex  align-items-center box__item box__item__right0">
                            <div className="point__img">
                                <img className="" src={imgs[1]} alt="" />
                            </div>
                            <div className="point__text">
                                <p>
                                    not contain carbocystein.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-lg-5 col-md-5 d-flex  align-items-center  box__item  box__item__left0">
                            <div className="point__text">
                                <p>
                                    not contain formaldehyde.
                                </p>
                            </div>
                            <div className="point__img ">
                                <img className="" src={imgs[2]} alt="" />
                            </div>

                        </div>
                        <div className="col-lg-5 col-md-5 d-flex  align-items-center box__item box__item__right0">
                            <div className="point__img">
                                <img className="" src={imgs[3]} alt="" />
                            </div>
                            <div className="point__text">
                                <p>
                                    Hair Colouring.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
