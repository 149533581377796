import React from 'react'
import './myfaq.css'
import { Accordion } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { scrollToTop } from '../../functions/ScrollToTop'
export default function MyFaq() {
    return (
        <div className='container faq__Sec'>
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    I Canceled My Order, But It Is Charging On My Invoice, What Should I Do?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Contact the call center by e-mail <a href="mailto:vilchesprofissional@gmail.com">vilchesprofissional@gmail.com</a> or by phone: WhatsApp <a href="tel:+55 11 91253-2459">+55 11 91253-2459</a> or <a href="tel:+55 11 93437-0317">+55 11 93437-0317</a>  
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Is The Product Compatible With Any Chemistry?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Yes, it is compatible with any chemical, but the wick test is essential.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    I Want To Be A Distributor (A) What Should I Do?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Contact the call center by e-mail <a href="mailto:vilchesprofissional@gmail.com">vilchesprofissional@gmail.com</a> or by phone: WhatsApp <a href="tel:+55 11 91253-2459">+55 11 91253-2459</a> or <a href="tel:+55 11 93437-0317">+55 11 93437-0317</a>  
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    I Am Interested In Partnerships And Blogs. What Do I Do?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Contact the call center by e-mail <a href="mailto:vilchesprofissional@gmail.com">vilchesprofissional@gmail.com</a> or by phone: WhatsApp <a href="tel:+55 11 91253-2459">+55 11 91253-2459</a> or <a href="tel:+55 11 93437-0317">+55 11 93437-0317</a>  
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    I Got My Wrong Products, How Should I Proceed?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Contact the call center by e-mail <a href="mailto:vilchesprofissional@gmail.com">vilchesprofissional@gmail.com</a> or by phone: WhatsApp <a href="tel:+55 11 91253-2459">+55 11 91253-2459</a> or <a href="tel:+55 11 93437-0317">+55 11 93437-0317</a>  
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Can Pregnant Women Us Progressives?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Only with medical authorization.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    What Is The Deadline For My Product To Be Shipped?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    The deadline for posting the goods is up to 24 working hours after confirmation of payment, shipping and product data. The contracted delivery term will take effect from this date.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    After Purchasing What Happens?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    You will automatically receive an email at your email address registered on the platform of our website, where all your doubts about your order will be dealt with, being our main communication channel. If you can’t find our email in your inbox, check your junk or spam.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Are The Products Original?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Yes! We are distributors. Which means that we get the products directly from the brands themselves. 100% original.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Is Shipping Really Free?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Yes, our freight is free OVER <span>99,99$</span> for all countries.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    How Much How Long Can I Perform A Bbtox Application?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    The time can vary from person to person, because it involves much more than the durability of the product, usually around 15 days it may happen that the hair is already having a need for a new application, but we Recommend it after 30 days.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Is This Website Safe ?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    Yes, Here’s why: 
                    <ul>
                        <li>
                            VILCHES 𝔦s a company founded in 2015. We’ve always here in Brazil at the same address.
                        </li>
                        <li>
                        100% Original products. We are distributors of the main brands of Brazil.
                        </li>
                        <li>
                        Over 4.000 orders sent worldwide.
                        </li>
                        <li>
                        <NavLink onClick={()=>scrollToTop()} to='/Privacy'>Security and Privacy</NavLink>. Your e-mail and info won’t be shared with anyone.
                        </li>
                        <li>
                        Safe payment methods through Stripe. No scam.
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Payment Methods?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                    <ul>
                        <li>
                        You can finish your purchase using your credit card (visa and Master)
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    Shipping Free?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                        <ul>
                            <li>
                            you won’t need to pay for shipping, we have free shipping for all countries. We ship through FedEx.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    My Payment Has Been Confirmed. When Will I Receive My Products?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                        <ul>
                            <li>
                            We ship your product in 7 working days.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15" className='faq__box'>
                    <Accordion.Header className='faq__question'>
                    How Do I Cancel An Order?
                    </Accordion.Header>
                    <Accordion.Body className='faq__answer'>
                        <ul>
                            <li>
                            To cancel an order. You need to do it before it ships, after shipping, we can’t cancel an order.
                            </li>
                            <li>
                            In case you received a product and you need to cancel it, the shipping fee is gonna be the responsibility of the person who ordered the product the cancellation in only going to take effect after we check if the security seal of the product has not been violated.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}
