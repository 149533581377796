import React, { useEffect, useState } from 'react'
import './singleProduct.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getData } from '../../store/slices/productData';
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection';
import Swal from 'sweetalert2'
import { addItem} from '../../store/slices/cartSlice';
export default function SingleProduct() {

    const { name } = useParams();
    const dispatche = useDispatch();
    const { products, isLoading } = useSelector((state) => state.productReducer);
    const [product, setProduct] = useState([])

    useEffect(() => {
        if (product?.length === 0) {
            dispatche(getData());
            setProduct(products?.data?.find((el) => el?.name == name));
        }
        setProduct(products?.data?.find((el) => el?.name == name));
    }, [dispatche, name, products, product]);
    const isProductValid =
        product &&
        product.sizes &&
        product.sizes.length > 0 &&
        product.images &&
        product.images.length >= 3 &&
        product.images[0].name &&
        product.images[1].name &&
        product.images[2].name;

    const handleAddToCart = () => {
        dispatche(addItem(product))
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Item Added To Cart',
            showConfirmButton: false,
            timer: 2000
        })
    }
    return (
        <>
            <>
                <PagesHeroSection title="PRODUCT detail" content={`${product?.name?.length > 0 ? `${product?.name}` : ""}`} />
                {isProductValid && (
                    <div className="singleProductDetail__sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 single__product__OnResponsive">
                                    <div className="singleProductDetail__image d-flex">
                                        <img className='w-100 px-1' src={product?.images[0].name} alt='' />
                                        <img className='w-100 px-1' src={product?.images[1].name} alt='' />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="singleProductDetail__info">
                                        <h1>
                                            {product?.name}
                                        </h1>
                                        <div className="singleProductDetail__info__detail">
                                            <div className="text">
                                                <span className='span__tit'>old price:</span>
                                                {
                                                    product?.offer &&
                                                    <>
                                                        <span className='span__value old__price'>${product?.price}</span>
                                                    </>
                                                }
                                            </div>
                                            <div className="text">
                                                <span className='span__tit'>new price:</span>
                                                <span className='span__value'>${product?.price - product?.offer}</span>
                                            </div>
                                            <div className="text">
                                                <span className='span__tit'>size:</span>
                                                <span className='span__value'>{product?.sizes[0]?.amount} {product?.sizes[0]?.name}</span>
                                            </div>
                                        </div>
                                        <div className="singleProductDetail__info__action">
                                            <button onClick={handleAddToCart}>add to cart</button>
                                        </div>
                                        <div className="singleProductDetail__info__desc">
                                            <h1>Description</h1>
                                            <p>
                                                {product?.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>

        </>
    )
}
