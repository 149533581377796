import React from 'react'
import './shopCard.css'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../../functions/ScrollToTop'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { addItem } from '../../store/slices/cartSlice'
export default function ShopCard(props) {
    const dispatch = useDispatch();

    return (
        <div className='col-lg-4 col-md-6 col-sm-12 p-5'>
            <div className="productItem">
                <div className="productItem__img ">
                    <img className='w-100 product__front' src={props.product.images[0].name} alt=""
                        onMouseOver={e => (e.currentTarget.src = props.product.images[1].name)}
                        onMouseOut={e => (e.currentTarget.src = props.product.images[0].name)}
                    />
                </div>
                <div className="prodOverlay"></div>
                <div className="productItem__details text-center">
                    <div className="productItem__title">
                        <h4>
                            <Link to={"/shop/" + props.product?.name} className="nav-link" onClick={()=>
                                {
                                    scrollToTop()
                                }}>
                            {props.product.name}
                            </Link>
                        </h4>
                    </div>
                    <div className="productItem__price d-flex justify-content-center">
                        {
                            props.product.offer &&
                            <>
                            <span className='px-2 old__price'>
                                $ {props.product.price}
                            </span>
                            </>
                        }
                        <span className='px-2'>
                            $ {props.product.price - props.product.offer}
                        </span>
                    </div>
                    <div className="productItem__size d-flex justify-content-center">
                        <span className='px-2'>{props.product.sizes[0].amount} {props.product.sizes[0].name}</span>
                    </div>
                </div>
                <div className="productItem__icons d-flex justify-content-center align-items-center">
                    <i className="bi bi-cart px-2 fs-5"
                        onClick={()=>{
                            dispatch(addItem(props.product))
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Item Added To Cart',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }}
                    ></i>
                </div>
            </div>
        </div>
    )
}
