import React from 'react'
import './home.css'
import HeroSlider from '../../components/heroSlider/HeroSlider'
import BenfitHome from '../../components/benfitHomeSec/BenfitHome'
import NewArrival from '../../components/newArrival/NewArrival'
import BannerImage from '../../components/bannerImageSec/BannerImage'

import benfitImg1 from '../../assets/images/banner/img-rounded-1.png'
import benfitImg2 from '../../assets/images/banner/img-rounded-2.png'
import benfitImg3 from '../../assets/images/banner/img-rounded-3.png'
import benfitImg4 from '../../assets/images/banner/img-rounded-4.png'
import backGround from "../../assets/images/banner/bannerHomeNew.jpg"
import BottomSlider from '../../components/bottomSlider/BottomSlider'
export default function MyHome() {
    return (
        <>
            <HeroSlider />
            <BenfitHome imgs={[benfitImg1, benfitImg2, benfitImg3, benfitImg4]} backGround={backGround} />
            <NewArrival />
            <BannerImage />
            <BottomSlider />
        </>
    )
}
