import React, { useEffect, useState } from 'react';
import "./Login.css";
import { Col, Container, Row } from 'react-bootstrap';
import { LoginSchema } from '../../validation/LoginSchema';
import { useFormik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import { URL } from '../../functions/URLSTORE';
import Swal from 'sweetalert2';
import { addToLocalStorage } from '../../functions/Token';
import { scrollToTop } from '../../functions/ScrollToTop';

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [backEndErrors, setBackEndErrors] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (values, actions) => {
    const isValid = await LoginSchema.validate(values);
    if (isValid) {
      const res = await fetch(`${URL}/login`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      const response = await res.json();
      if (response.status) {
        addToLocalStorage("token",response.data.token);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 2000
        });
        actions.resetForm();
        setShowErrors(false);
        (async()=>{
          await navigate("/Cart");
          window.scrollTo({
            top: 210,
          })
        })()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!'
        });
        setBackEndErrors({ ...response.errors });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: LoginSchema,
    onSubmit,
  });

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const manageGoogleAccount = () => {
    window.location.href = "http://backend.vilchesprofessional.com/redirect/google";

  };

  return (
    <>
      {
        loading ?
          <div className="loaderContainer">
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loader loader-inner"></div>
              </div>
            </div>
          </div>
          :
          <div className='registerAndLoginPage position-relative'>
            <div className="icon__routeBackToCart position-absolute" onClick={() => {
              (async () => {
                await navigate("/cart");
                window.scrollTo({
                  top: 210,
                });
              })()
            }}>
              <i className="bi bi-box-arrow-left fs-1"></i>
            </div>
            <Container>
              <Row>
                <Col md={8} className='mx-auto registeration__form login__form'>
                  <h2 className='text-center mb-4'>Login</h2>

                  <form
                    action="Post"
                    className='px-5 pt-3 row gapY-3'
                    onSubmit={handleSubmit}
                    onBlur={handleBlur}
                  >

                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-12`}>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder='Email'
                        onChange={handleChange}
                        value={values.email.trim()}
                        className={`loginForm__email w-100 ${(errors.email && touched.email) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='mb-4 text-danger text-capitalize'>{errors.email}</p>}
                    </div>
                    <div className={`${showErrors ? "mb-2" : "mb-4"} col-12`}>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder='Password'
                        onChange={handleChange}
                        value={values.password.trim()}
                        className={`loginForm__password w-100 ${(errors.password && touched.password) ? "input-error" : ""}`}
                      />
                      {showErrors && <p className='mb-5 text-danger text-capitalize'>{errors.password}</p>}
                    </div>



                    <div className="registeration__form__btn d-flex justify-content-end">

                      <button type='submit' onClick={() => setShowErrors(true)}
                      >Login</button>
                    </div>
                    {/* <div className="registeration__form__btn__google d-flex justify-content-center align-items-center my-3" 
                    onClick={manageGoogleAccount}>

                      <div className="icon__handler">
                        <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt='google-Icon' />
                      </div>
                      <span>
                        sign in with google
                      </span>
                    </div> */}
                    <div className="col-12 text-light">
                      <span>Don't Have and account?</span>
                      <NavLink to="/Register" onClick={() => scrollToTop()} className="gotoLoginLink ms-2">
                        Create New Account
                      </NavLink>
                    </div>
                  </form>

                </Col>
              </Row>
            </Container>
          </div>
      }
    </>
  );
};

export default Login;