import React, { useEffect } from 'react';
import "./CartCanvas.css";
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../functions/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { calcTotal, decreaseQuantity, increaseQuantity, removeItem } from '../../store/slices/cartSlice';
import Swal from 'sweetalert2';
const CartCanvas = () => {
    const navigate = useNavigate();
    let cartItems = useSelector(state => state.cartReducer.cart)
    const dispatche = useDispatch()
  const totalPrice = useSelector(state => state.cartReducer.totalPrice)
  useEffect(() => {
    dispatche(calcTotal())
}, [dispatche, totalPrice, cartItems])

    return (
        <div className='cart__canvas'>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Your Cart</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body px-3">
                    {
                        cartItems.map((item) => {
                            return <div className="offcanvas__cartItem position-relative" key={item?.id}>
                                <div className="offcanvas__cartItem__remove position-absolute" onClick={() => {
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes, delete it!'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                        dispatche(removeItem(item.id))
                                        Swal.fire(
                                            'Deleted!',
                                            'Product has been deleted from Cart.',
                                            'success'
                                        );
                                        };
                                    });
                                }}>
                                    x
                                </div>
                                <div className="container-fluid">
                                    <div className="cart__canvas__item row">
                                        <div className="cart__item__image col-4">
                                            <img className='w-100' src={item?.images[0]?.name} alt="Cart item" />
                                        </div>
                                        <div className="cart__item__content col-8">
                                            <h5>{item?.name}</h5>
                                            <p className="cart__item__content__volumn">
                                                {item?.sizes[0]?.amount} {item?.sizes[0]?.name}
                                            </p>
                                            {
                                                item?.offer &&
                                                <>
                                                    <p className="cart__item__content__price old__price">
                                                    $ {item?.price}
                                                    </p>
                                                </>
                                            }
                                            <p className="cart__item__content__price">
                                            $ {item?.price - item?.offer}
                                            </p>
                                            <div className="cart__item__counters d-flex">
                                                <div className="cart__item__counterNegative" onClick={() => {
                                                    dispatche(decreaseQuantity(item.id))
                                                }}>-</div>
                                                <div className="cart__item__counterNumber">
                                                    <input
                                                        type="number"
                                                        name='cartCounter'
                                                        id='cartCounter'
                                                        className='text-center'
                                                        value={item.qty}
                                                        onChange={()=>{}}
                                                    />
                                                </div>
                                                <div className="cart__item__counterPositive" onClick={() => {
                                                    dispatche(increaseQuantity(item.id))
                                                }}>+</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                    <div className="offcanvas__cartTotalPrice d-flex align-items-center justify-content-between">
                        <h6>Total</h6>
                        <p className='offcanvas__cartTotalPrice__number'>$ {totalPrice}</p>
                    </div>
                    <div className="offcanvas__cart__navigate">
                        <p className="cart__alert">
                            Shipping, taxes, and discounts will be calculated at checkout.
                        </p>
                        <button data-bs-dismiss="offcanvas" aria-label="Close" className='cart__navigateBtn' onClick={() => {
                            navigate("/cart");
                            scrollToTop();
                        }}>
                            View Cart
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartCanvas;