import React from 'react';
import "./ShopPage.css";
import NewArrival from '../../components/newArrival/NewArrival';
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection';
const ShopPage = () => {
  return (
  
    <div className='shop__page'>
      <PagesHeroSection title="collection" content="Our Famous Oil" />
      <NewArrival />
    </div>
  );
};
export default ShopPage;