import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decreaseQuantity, increaseQuantity, removeAllItems, removeItem } from '../../store/slices/cartSlice';
import Swal from 'sweetalert2';

const CartList = () => {
  let cartItems = useSelector(state => state.cartReducer.cart)
  const dispatche = useDispatch()

  return (
    <div className='col-lg-8'>
      <div className="topCartContent d-flex justify-content-between align-items-center">
        <h3>Products</h3>
        <div className="btn__removeAll">
          <button className="btnRemoveAll px-5"
          onClick={()=>{
            if(cartItems.length > 0){
              Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatche(removeAllItems());
                  Swal.fire(
                    'Deleted!',
                    'All Products Has Been Deleted.',
                    'success'
                  );
                };
              });
            }
          }}
          >Remove All</button>
        </div>
      </div>
      <div className="cartList__items">
        {
          cartItems?.map((item) => {
            return <div className="cartList__cartItem position-relative" key={item?.id}>
              <div className="cartList__cartItem__remove position-absolute" onClick={()=>{
                        Swal.fire({
                          title: 'Are you sure?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatche(removeItem(item.id))
                            Swal.fire(
                              'Deleted!',
                              'Product has been deleted from Cart.',
                              'success'
                            )
                          }
                        })
                      }}>
                x
              </div>
              <div className="container-fluid h-100">
                <div className="cartList__cartItem__item row h-100">
                  <div className="cart__item__image col-4">
                    <img className='w-100' src={item?.images[0]?.name} alt="Cart item" />
                  </div>
                  <div className="cart__item__content col-8">
                    <h5>{item?.name}</h5>
                    {
                      item?.offer &&
                      <>
                        <p className="cart__item__content__price old__price">
                          $ {item?.price}
                        </p>
                      </>
                    }
                    <p className="cart__item__content__price">
                      $ {item?.price - item?.offer}
                    </p>
                    <p className="cart__item__content__volumn">
                      {item?.sizes[0]?.amount} {item?.sizes[0]?.name}
                    </p>
                    <div className="cart__item__counters d-flex">
                      <div className="cart__item__counterNegative" 
                      onClick={()=>{
                        dispatche(decreaseQuantity(item.id))
                      }}>-</div>
                      <div className="cart__item__counterNumber">
                        <input
                          type="number"
                          name='cartCounter'
                          id='cartCounter'
                          className='text-center'
                          onChange={()=>{}}
                          value={item.qty} />
                      </div>
                      <div className="cart__item__counterPositive"  
                      onClick={()=>{
                        dispatche(increaseQuantity(item.id))
                      }}>+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })
        }


      </div>
    </div>
  );
};

export default CartList;
