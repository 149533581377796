import React from 'react';
import "./About__Us.css";
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection';
import AboutUsSec from '../../components/aboutUsSec/AboutUsSec';

const About__Us = () => {
  return (
    <>
      <PagesHeroSection title="About Us" content="About Us" />
      <AboutUsSec/>
    </>
  );
};

export default About__Us;