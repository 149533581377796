import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {URL} from '../../functions/URLSTORE';
// const URL = `https://vilchesprofessional.com/api/products`
const initialState ={
    products: [],
    isLoading:false,
    isError: false
}
export const getData = createAsyncThunk(
    "product/getData",
    async (id, thunkAPI) => {
        const {rejectWithValue} =thunkAPI
        try {
            console.log(id);
            const response = await axios.get(`${URL}/products`)
            return response.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)
const productSlice = createSlice({
    name: "product",
    initialState,
    reducers:{},
    extraReducers:{
        [getData.pending]: (state) => {
            state.isLoading = true
        },
        [getData.fulfilled]: (state, action) => {
            state.isLoading = false
            state.products = action.payload;
        },
        [getData.rejected]: (state, action) => {
            state.isLoading = false
            state.isError = action.payload
        },
    }
})

export const productReducer = productSlice.reducer;
export const productActions = productSlice.actions;