import React from 'react'
import './aboutUsSec.css'
import aboutImg1 from '../../assets/images/about__imgs/about1.png'
import aboutImg2 from '../../assets/images/about__imgs/about2.png'
import aboutImg3 from '../../assets/images/about__imgs/about3.png'
import BenfitDesc from '../benfitsDesc/BenfitDesc'
import AnimatedTree from '../animatedTree/AnimatedTree'
const aboutUsBoxes = [
  {
    "textTit": " Mixture of organic acids of vegetable origin",
    "textDesc": "Experience the benefits of this unique mixture and embrace the potential it holds for enhancing your daily life.",
    "icon": "bi bi-droplet-half"
  },
  {
    "textTit": " Blend of moisturizing Brazilian seed oils",
    "textDesc": "Indulge in the nourishing power of our blend of moisturizing Brazilian seed oils. This luxurious formula deeply hydrates and replenishes your skin, leaving it soft, supple, and radiant.",
    "icon": "bi bi-flower3"
  },
  {
    "textTit": "Compatible with all chemical procedures.",
    "textDesc": "Our versatile product is compatible with all chemical procedures, allowing you to incorporate it seamlessly into your beauty routine. Whether it's coloring, perming, or straightening, this solution ensures optimal results without compromising the integrity of your hair or skin.",
    "icon": "bi bi-tag-fill"
  }
]
export default function AboutUsSec() {
  return (
    <div className='abouUs__Sec'>
        <div className="container">
          <div className="aboutUs__row pt-0">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="aboutUs__img  d-flex justify-content-center">
                  <img className='w-100' src={aboutImg1} alt="about_img1" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex align-items-center">
                <div className="aboutUs__text">
                  <h4>
                    Hair Product
                  </h4>
                  <h1>
                    perfect smooth
                  </h1>
                  <p>
                    Experience the magic of our Perfect Smooth Hair treatment. This transformative solution delivers flawlessly smooth and shiny locks. Say goodbye to frizz and hello to sleek, manageable hair. Unlock the secret to perfect smoothness and indulge in the luxury of silky, soft strands.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutUs__row">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex align-items-center">
                <div className="aboutUs__text">
                  <h4>
                    Hair Product
                  </h4>
                  <h1>
                    organic hair strengthening
                  </h1>
                  <p>
                    Developed with the most advanced hair straightening technology with 0% formaldehyde, it guarantees perfectly straight hair, reduces volume and frizz; providing shine and naturalness for any hair type.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6  aboutUs__img__onSmall">
                <div className="aboutUs__img">
                  <img className='w-100' src={aboutImg2} alt="about_img1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BenfitDesc />
        <div className="position-relative">
          <AnimatedTree />
          <div className="container">
            <div className="aboutUs__perfect__choosse">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="aboutUs__perfect__choosse__img">
                    <img className='w-100' src={aboutImg3} alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="aboutUs__perfect__choosse__text">
                    <div className="aboutUs__perfect__choosse__text__heading">
                      <h1>
                        Perfect & natural product
                      </h1>
                      <p>
                        Discover the perfect and natural product that meets all your needs. Experience the beauty of pure ingredients, carefully selected to deliver exceptional results while honoring your body and the environment.
                      </p>
                    </div>
                    <div className="aboutUs__perfect__choosse__text__list">
                      {aboutUsBoxes.map((box) => {
                        return (
                          <div className="listBox d-flex align-items-baseline" key={box.textTit}>
                            <div className="iconBox">
                              <i className={`${box.icon}`}></i>
                            </div>

                            <div className="textBox">
                              <h1>
                                {box.textTit}
                              </h1>
                              <p>
                                {box.textDesc}
                              </p>
                            </div>

                          </div>
                        )
                      })
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
