import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import './myNav.css';
import logo2 from '../../assets/images/logo.png';
import { scrollToTop } from '../../functions/ScrollToTop';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getFromLocalStorage, removeFromLocalStorage } from '../../functions/Token';
import { URL } from '../../functions/URLSTORE';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

export default function MyNav({ scrollToggle }) {
    const [, setShow] = useState(false);
    const token = getFromLocalStorage("token");
    const [tokenState, setTokenState] = useState(token);
    const { totalItems } = useSelector(state => state.cartReducer);
    const [totalCounterNum, setTotalCounterNum] = useState(0);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    function handleOffcanvasToggle() {
        setShowOffcanvas(!showOffcanvas);
    }
    useEffect(() => {
        setTotalCounterNum(totalItems);
    }, [totalItems]);

    const handleLogout = () => {
        removeFromLocalStorage("token");
        (async () => {
            const promise = await fetch(`${URL}/logout`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            const res = await promise.json();
            if (res.status) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${res.message}`,
                    showConfirmButton: false,
                    timer: 2000
                });
                setTokenState(null);
            }
        })();
    }

    return (
        <Navbar expand="lg" className={`nav__Bg ${scrollToggle ? "nav__fixed py-3 navTransformationDown" : "nav__absolute pb-3"} align-items-center`}>
            <Container>
                <Navbar.Brand>
                    <NavLink to="/">
                        <img src={logo2} alt="" />
                    </NavLink>
                </Navbar.Brand>
                <div className="blockOnSmallScreen ms-auto me-3">
                    <NavLink
                        className="nav-link nav__icon px-3 fs-3"
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                    >
                        <span className="cartCounter">
                            {totalCounterNum}
                        </span>
                        <i className="bi bi-cart" ></i>
                    </NavLink>
                </div>
                <Navbar.Toggle onClick={handleOffcanvasToggle} aria-controls="basic-navbar-nav" />
              {/* start navbar min-width 992px */}
                <Navbar.Collapse id="navbar-nav" className='Navbar__Collapse__none__on__med'>
                    <Nav className="mx-auto" >
                                    <NavLink
                                        onClick={() =>{scrollToTop();
                                        }}
                                        className="nav-link px-4 nav__link__style"
                                        to="/">
                                        Home
                                    </NavLink>
                                    <NavLink
                                        onClick={() =>{scrollToTop();
                                        }
                                        
                                        }
                                        className="nav-link px-4 nav__link__style"
                                        to="/shop">
                                        shop
                                    </NavLink>
                                    <NavLink
                                        onClick={() =>{scrollToTop();
                                        } 

                                    }
                                        className="nav-link px-4 nav__link__style"
                                        to="/about">
                                        About
                                    </NavLink>
                                    <NavLink
                                        onClick={() =>{scrollToTop();
                                        } }
                                        aria-label="Close"
                                        className="nav-link px-4 nav__link__style"
                                        to="/faq">
                                        Faq
                                    </NavLink>
                                    <NavLink
                                        onClick={() =>{scrollToTop();
                                        } }
                                        className="nav-link px-4 nav__link__style"
                                        to="/contact">
                                        contact
                                    </NavLink>
                                    {
                                        tokenState &&
                                        <NavLink className="nav-link nav__icon fs-2 p-3 nav__logoutPart nav__link__style nav__logoutPart-responsive" onClick={handleLogout}>
                                            <span>Logout</span>
                                        </NavLink>
                                    }
                    </Nav>
                </Navbar.Collapse>
              {/* end navbar min-width 992px */}
              {/* start navbar min-width 320px */}
                    <Navbar.Offcanvas id="offcanvasNavbar" className='Navbar__offCanvas__none__on__lg'       aria-labelledby="offcanvasNavbarLabel"
                        show={showOffcanvas} onHide={handleOffcanvasToggle}
                        placement="start">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='offCanvas__head' id="offcanvasNavbarLabel">

                                <NavLink to="/" className="px-4">
                                    <img src={logo2} alt="" />
                                </NavLink>

                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="mx-auto" >
                                <NavLink
                                    onClick={() =>{scrollToTop();
                                    handleOffcanvasToggle()
                                    }}
                                    className="nav-link px-4 nav__link__style"
                                    to="/">
                                    Home
                                </NavLink>
                                <NavLink
                                    onClick={() =>{scrollToTop();
                                    handleOffcanvasToggle()
                                    }
                                      
                                    }
                                    className="nav-link px-4 nav__link__style"
                                    to="/shop">
                                    shop
                                </NavLink>
                                <NavLink
                                    onClick={() =>{scrollToTop();
                                    handleOffcanvasToggle()
                                    } 

                                }
                                    className="nav-link px-4 nav__link__style"
                                    to="/about">
                                    About
                                </NavLink>
                                <NavLink
                                    onClick={() =>{scrollToTop();
                                    handleOffcanvasToggle()
                                    } }
                                    aria-label="Close"
                                    className="nav-link px-4 nav__link__style"
                                    to="/faq">
                                    Faq
                                </NavLink>
                                <NavLink
                                    onClick={() =>{scrollToTop();
                                    handleOffcanvasToggle()
                                    } }
                                    className="nav-link px-4 nav__link__style"
                                    to="/contact">
                                    contact
                                </NavLink>
                                {
                                    tokenState &&
                                    <NavLink className="nav-link nav__icon fs-2 p-3 nav__logoutPart nav__link__style nav__logoutPart-responsive" onClick={handleLogout}>
                                        <span>Logout</span>
                                    </NavLink>
                                }
                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
              {/* end navbar min-width 320px */}
                <Nav className='noneOnMediumScreen'>
                    {
                        tokenState &&
                        <NavLink className="nav-link nav__icon fs-4 p-3 nav__logoutPart me-2" onClick={handleLogout}>
                            <span>Logout</span>
                        </NavLink>
                    }
                    <NavLink
                        className="nav-link nav__icon px-3 fs-5"
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                    >
                        <span className="cartCounter">
                            {totalCounterNum}
                        </span>
                        <i style={{ "color": "#000" }} className="bi bi-cart"></i>
                    </NavLink>
                </Nav>
            </Container>
        </Navbar>
    )
}
