import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { calcTotal, removeAllItems } from '../../store/slices/cartSlice';
import {URL} from "../../functions/URLSTORE";
import { getFromLocalStorage } from '../../functions/Token';
import "./CartCheckoutPart.css";
import Swal from 'sweetalert2';
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
});

const CartCheckoutPart = () => {
  const cartItems = useSelector(state => state.cartReducer.cart);
  const totalItems = useSelector(state => state.cartReducer.totalItems);
  const totalPrice = useSelector(state => state.cartReducer.totalPrice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const currentRoute = window.location.href;

  // useEffect(()=>{
  //   if(currentRoute.endsWith("google=true")){
  //     window.location.href = "http://backend.vilchesprofessional.com/redirect/google";
  //   };
  // },[]);

  useEffect(() => {
    dispatch(calcTotal());
  }, [dispatch, totalPrice, totalItems, cartItems]);

  const handleCheckOutBtn = () => {
    const token = getFromLocalStorage("token");
    if(cartItems.length > 0){
      swalWithBootstrapButtons.fire({
        title: 'Choose The Way You Want To Pay With',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm Online',
        cancelButtonText: 'Pay Offline',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if(token){
            (async () => {
              const fetchedData = await fetch(`${URL}/orders`, {
                method: 'POST',
                headers: {
                  'content-type': 'application/json',
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ "paying_type": "online", "products": cartItems })
              });
              const response = await fetchedData.json();
              window.location.href = `${response.data.url}`;
            })();
          }else {
            navigate("/Login");
            window.scrollTo({
              top: 0,
            })
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          if(token){
          (async () => {
            const fetchedData = await fetch(`${URL}/orders`, {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
                "Authorization": `Bearer ${token}`
              },
              body: JSON.stringify({ "paying_type": "offline", "products": cartItems })
            });
            const response = await fetchedData.json();
            if(response.status){
              dispatch(removeAllItems());
              window.scrollTo({
                top: 210,
              })
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${response.message}`,
                showConfirmButton: false,
                timer: 2000
              });
            }else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: `${response.message}`,
                showConfirmButton: false,
                timer: 2000
              });
            }
          })();
        }else {
          navigate("/Login");
          window.scrollTo({
            top: 0,
          })
        }
        }
      })
    }
  }

  return (
    <div className='col-lg-4'>
      <h3>Order Summary</h3>
      <div className="cartList__Sec__subTotal">
        <span>Subtotal : </span>
        <span className='cartList__Sec__subTotalPrice'>$ {totalPrice}</span>
      </div>
      <div className="cartList__payMentPart">
        <p>Shipping, taxes, and discounts will be calculated at checkout.</p>
          <button onClick={handleCheckOutBtn}>Proceed To CheckOut</button>
      </div>
    </div>
  );
};

export default CartCheckoutPart;
