import React, { useState } from 'react';
import "./ContactMainSection.css";
import { ContactSchema } from '../../validation/ContactSchema';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import {URL} from "../../functions/URLSTORE";
const ContactMainSection = () => {
  const [showErrors,setShowErrors] = useState(false);
  const [backEndErrors,setBackEndErrors] = useState(null);
  const onSubmit = async ( values , actions) => {
    const isValid = await ContactSchema.validate(values);
    if(isValid){
      const res = await fetch(`${URL}/messages`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(values)
      });
      const response = await res.json();
      if(response.status){
        setShowErrors(false);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `${response.message}`,
          showConfirmButton: false,
          timer: 1000
        });
        actions.resetForm();
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!'
        });
        setBackEndErrors({...response.errors});
      }
    }
  };

  const { values , errors , touched , handleBlur , handleChange , handleSubmit} = useFormik({
    initialValues:{
      name: "",
      email: "",
      subject: "",
      message: ""
    },
    validationSchema: ContactSchema,
    onSubmit,
  });

  return (
    <div className='contact__mainSec'>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ul className='contact__mainSec__list d-flex flex-column gap-4'>
              <li className="contact__mainSec__listItem row px-3 py-5 align-items-center">
                <div className="contact__mainSec__listItem__iconContainer col-3">
                  <div className="contact__mainSec__listItem__icon">
                    <i className="bi bi-telephone-fill"></i>
                  </div>
                </div>
                <div className="contact__mainSec__listItem__text col-9">
                  <h5>Phone</h5>
                  <ul>
                    <li>
                    <a className='nav-link' href="tel:+55 11 93437-0317">+55 11 93437-0317</a>  
                    </li>
                  </ul>
                </div>
              </li>
              <li className="contact__mainSec__listItem row px-3 py-5 align-items-center">
                <div className="contact__mainSec__listItem__iconContainer col-3">
                  <div className="contact__mainSec__listItem__icon">
                    <i className="bi bi-envelope"></i>
                  </div>
                </div>
                <div className="contact__mainSec__listItem__text col-9">
                  <h5>Email</h5>
                  <ul>
                    <li><a className='nav-link' href="mailto:vilchesprofessional@gmail.com">vilchesprofessional@gmail.com</a></li>
                  </ul>
                </div>
              </li>
              <li className="contact__mainSec__listItem row px-3 py-5 align-items-center">
                <div className="contact__mainSec__listItem__iconContainer col-3">
                  <div className="contact__mainSec__listItem__icon">
                    <i className="bi bi-geo-alt"></i>
                  </div>
                </div>
                <div className="contact__mainSec__listItem__text col-9">
                  <h5>Address</h5>
                  <ul>
                    <li>Rua Beethoven 401 jardim arco íris diadema São Paulo Brasil</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-8">
            <div className="contact__mainSec__form ps-lg-3">

              <h3 className='mb-4 fs-3'>Contact Form</h3>

              <form onSubmit={handleSubmit} onBlur={handleBlur} method='POST'>
                <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                  <input 
                  className={`w-100 ${((errors.name && touched.name) || backEndErrors )? "input-error" : ""}`}
                  type="text" 
                  name='name' 
                  id='name'
                  value={values.name}
                  onChange={handleChange}
                  placeholder='Name' 
                  />
                  {showErrors && <p className='text-danger text-capitalize'>{ errors.name || backEndErrors?.name }</p>}
                </div>

                <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                  <input 
                  className={`w-100 ${((errors.email && touched.email) || backEndErrors )? "input-error" : ""}`}
                  type="email" 
                  name="email" 
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder='Email'
                  />
                  {showErrors && <p className='text-danger text-capitalize'>{errors.email || backEndErrors?.email}</p>}
                </div>

                <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                  <input 
                  className={`w-100 ${((errors.subject && touched.subject) || backEndErrors )? "input-error" : ""}`}
                  type="text" 
                  name="subject" 
                  id="subject"
                  value={values.subject}
                  onChange={handleChange}
                  placeholder='Subject'
                  />
                  {showErrors && <p className='text-danger text-capitalize'>{ errors.subject || backEndErrors?.subject}</p>}
                </div>

                <div className={`${showErrors ? "mb-2" : "mb-4"}`}>
                  <textarea
                  className={`w-100 ${((errors.message && touched.message) || backEndErrors )? "input-error" : ""}`}
                  name="message" 
                  id="contactMessage"
                  value={values.message}
                  onChange={handleChange}
                  placeholder='Message'
                  ></textarea>
                  {showErrors && <p className='text-danger text-capitalize'>{errors.message || backEndErrors?.message}</p>}
                </div>

                <button 
                className='contact__mainSec__form__submitBtn' 
                type='submit'
                id="submitBtn"
                name='submitBtn'
                onClick={()=> setShowErrors(true)}
                >
                  Send
                </button>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMainSection;