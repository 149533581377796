import React from 'react'
import './animatedTree.css'
import animatedImage from "../../assets/images/animate/slider-leaf-img.webp";
export default function AnimatedTree() {
    return (
        <>
            <div className='tree__container'>
                <img src={animatedImage} alt="Hero Section animated" />
            </div>
        </>
    )
}
