import React from 'react'
import './myPrivacy.css'
import { NavLink } from 'react-router-dom'
import { scrollToTop } from '../../functions/ScrollToTop'
export default function MyPrivacy() {
    return (
        <div className='privacySec'>
            <div className="container">
                <div className="privacySec__handler">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="privacy__Item py-3">
                                <h1>
                                    INFORMATION
                                </h1>
                                <p className='privacy__sub__tit'>
                                    Is This Site Safe ?
                                </p>
                                <p>
                                    We make sure to guarantee your security at the time of purchase, so all data provided is encrypted in am SSL 256Bits secure environment and we do not have access to your credit card numbers.
                                </p>
                                <p>
                                    Data exchange takes place directly between your card servers, without any human access. In addition, we are a company with more than a decade of history built by people who believe in work done with honesty and transparency.
                                </p>
                                <p>
                                    Our service team will always be available to answer questions and resolve any problems should this happen. We offer stock in Brazil of 100% original products with warranty, fast delivery options and full support in post-purchase , always respecting all laws of the Brazilian consumer protection code.
                                </p>
                                <p>
                                    For questions, we are available to clarify by e-mail : <a href="mailto:vilchesprofissional@gmail.com">vilchesprofissional@gmail.com</a>
                                </p>
                            </div>
                            <div className="privacy__Item py-3">
                                <h1>
                                    About VILCHES profissional
                                </h1>
                                <p>
                                    For 2 years bringing Beauty and care to all of brazil, VILCHES profissional mission is to be an international reference in the digital cosmetics market, in addition to offering the best and profissional hair products with the best cost-benefit ratio.
                                </p>
                                <p>
                                    The desire to transform the way of consumption among our customers only grows. Therefore, we are always reinventing ourselves to facilitate the purchase and access to best organic hair care treatments and hair smoothening products, with personalized service at all stages so that your experience is as incredible as possible.
                                </p>
                                <p>
                                    We want you to feel connected with everything that is new and efficient that universe of beauty. Stay on top of the main trends and always be surprised with quality products at a price that fits in your pocket.
                                </p>
                                <p>
                                    With every closed purchase, we want a smile of satisfaction for the experience of shopping with us!
                                </p>
                            </div>
                            <div className="privacy__Item py-3">
                                <h1>
                                    Our Story
                                </h1>
                                <p>
                                    VILCHES profissional emerged in 2019 in The City of São Paulo, in thé stars of São Paulo. And since then , the entire team has been working daily to offer personalized service, confidence, security and 100% original and professional hair products to you.
                                </p>
                            </div>
                            <div className="privacy__Item py-3">
                                <h1>
                                    Our Differences
                                </h1>
                                <p>
                                    We value the trust and security of all our costumers. Therefore, we work only with 100% original and new products and best hair straighteners directly from the brand or manufacturer, without giving up quality.
                                </p>
                                <p>
                                    As a result, we buy in large quantities in order to have stock ready for delivery and always with the best cost-benefit in the market.
                                    In addition, we offer fast and safe delivery to Brazil and abroad, always following the laws of the Brazilian consumer protection code.
                                </p>
                            </div>
                            <div className="privacy__Item py-3">
                                <h1>
                                    Security And Privacy
                                </h1>
                                <p>
                                    VILCHES profissional 𝔦𝔰 committed to the privacy and security of its customers throughout the browsing and purchasing process in our virtual store.
                                </p>
                                <p>
                                    Your personal data for addressing, payment and order content will not be disclosed under any circumstances, Rest assured that they will be used for purposes other than processing orders placed.
                                </p>
                                <p>
                                    Regrading data traffic security, any transaction that involves payment, whether by credit card or not, will be encrypted with SSL (Secure socket layer) technology. This means that only the store has access to this data. When you fill in the data and send it to us, it is encrypted, which makes it unrecognizable during the journey over the internet. Ensuring confidentiality.
                                </p>
                                <p>
                                    All pages of the store are enabled with P3P (Platform for Privacy Preferences), which allows greater over your personal information.
                                </p>
                            </div>
                            <div className="privacy__Item py-3">
                                <h1>
                                    Buy More, Save More
                                </h1>
                                <p>
                                    Here’s our rules for wholesale prices.<br />
                                    You can automatically get a discount based on the quantity of products on your cart.
                                </p>
                                <p className='privacy__sub__tit'>
                                    Just choose accordingly :
                                </p>
                                <ul>
                                    <li>10% OFF - For any 2 products.</li>
                                    <li>15% OFF - Minimum quantity : 4 products.</li>
                                    <li>20% OFF- Minimum quantity : 6 products.</li>
                                    <li>25% OFF - Minimum quantity : 12 Products.</li>
                                </ul>
                                <p>
                                    Our products has free shipping
                                </p>
                            </div>
                            <div className="privacy__Item py-3">
                                <h1>
                                    How To Buy
                                </h1>
                                <p>
                                    Buying at VILCHES profissional is very easy. Our virtual store was developed with the best in technology, For a quick, pleasant and easy navigation. Check the purchase step-by-step below :
                                </p>
                                <p className='privacy__sub__tit'>
                                    1- Choice of product
                                </p>
                                <ul>
                                    <li>Through the <NavLink onClick={() => scrollToTop()} to='/'>Home</NavLink> page or <NavLink onClick={() => scrollToTop()} to='/shop'>Shop</NavLink> page, which you find at the top of the store. This field will allow you to find all available products on the site.</li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    2- Products Details
                                </p>
                                <ul>
                                    <li>
                                        Clicking on any product in the showcase, you will have access to all the details related to it, such as the detailed values and instalments, description, etc
                                    </li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    3- Putting the product in the shopping cart
                                </p>
                                <ul>
                                    <li>
                                        when choosing the product, and click on “<i class="bi bi-cart"></i>” or “Add To Cart”
                                    </li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    4- My Cart
                                </p>
                                <ul>
                                    <li>
                                        Check all your order date: quantity, values (unit and total), instalment payments.
                                    </li>
                                    <li>
                                        Check all product value and discounts.
                                    </li>
                                    <li>
                                        If you want to close your order, click on “Proceed To CheckOut”
                                    </li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    5- Delivery
                                </p>
                                <ul>
                                    <li>
                                        After click on “Proceed To CheckOut” you go to Register page as your first time if you already have an account go to Login page if not loged in
                                    </li>
                                    <li>
                                        Register a shipping address
                                    </li>
                                    <li>
                                        Fill in the fields (When necessary) to complete your purchase.
                                    </li>
                                    <li>
                                        Select your preferred delivery method
                                    </li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    6- Closing the Order
                                </p>
                                <ul>
                                    <li>
                                        Check your purchase data again, to make sure that the information is in accordance with your order
                                    </li>
                                    <li>
                                        Choose the desired payment method. Note : All forms of payment are exposed on this page, as well as the instalment data for the purchase, according to each modality.
                                    </li>
                                    <li>
                                        Finalizing the purchase
                                    </li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    7- Finalizing the purchase
                                </p>
                                <ul>
                                    <li>
                                        Check the information of the total purchase and the instalment chosen.
                                    </li>
                                    <li>
                                        Click PAY
                                    </li>
                                </ul>
                                <p className='privacy__sub__tit'>
                                    8- Information about your order
                                </p>
                                <ul>
                                    <li>
                                        as soon as your purchase is completed, you will see your order information on the screen
                                    </li>
                                    <li>
                                        Then, you will receive a message in the email informed in your registration, with all the information related to the order just placed
                                    </li>
                                    <li>
                                        Note: it is important that you register the email correctly, so that you can receive messages sent by our store.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
