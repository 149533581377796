import React from 'react';
import "./ContactUs.css";
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection';
import ContactMap from '../../components/contactMap/ContactMap';
import ContactMainSection from '../../components/contactMainSection/ContactMainSection';

const ContactUs = () => {
  return (
    <>
      <PagesHeroSection title="Contact Us" content="contact Us" />
      <ContactMap />
      <ContactMainSection />
    </>
  );
};

export default ContactUs;
