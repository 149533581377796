import React from 'react'
import PagesHeroSection from '../../components/pagesHeroSection/PagesHeroSection'
import MyPrivacy from '../../components/myprivacySec/MyPrivacy'

export default function Privacy() {
  return (
    <>
       <PagesHeroSection title="Privacy Policy" content="Privacy Policy" />
       <MyPrivacy/>
    </>
  )
}
