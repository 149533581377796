import React from 'react'
import './bannerImage.css'
export default function BannerImage() {
    return (
        <div className='bannerImageSec'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-8">
                        <div className="bannerImage__text">
                            <h4>
                                Soft & Silky Hair
                            </h4>
                            <p>
                                Extreme High gloss Protein is the Brazilian Blowout Straightening Treatment that you’ve been looking for! It’s designed for fortifying hair with essential vitamins and minerals, and it works great with any hair type. In addition, it straightens, hydrates, and rebuilds the hair.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
