import { Col, Container, Row } from "react-bootstrap";
import "./ErrorSection.css";

const ErrorSection = ({errorMSG}) => {
  return (
    <div className="errorSection d-flex align-items-center justify-content-center">
      <Container>
        <Row>
            <Col lg={10} className="mx-auto text-center">
                <h2 className="mb-3">404</h2>
                <p className="errorAlert mb-3">{errorMSG || "Oops! That page can’t be found"}</p>
                <p className="errorApologise">
                    Sorry, but the page you are looking for does not existing
                </p>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ErrorSection
