import React, { useEffect, useState } from 'react'
import ShopCard from '../shopCard/ShopCard'
import './newArrival.css';
import {URL} from "../../functions/URLSTORE";

export default function NewArrival() {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        fetch(`${URL}/products`)
            .then(res => res.json())
            .then(data => setProducts(data))
    }, []);

    return (
        <div className='newArrivalSec'>
            <div className="container">
                <div className="newArrival__title">
                    <h4>Products</h4>
                </div>
                <div className="newArrival__products text-center">
                    <div className="row justify-content-center">
                        {
                            products?.data?.map((product)=>{
                                return <ShopCard key={product.id} product={product}/>
                    })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
