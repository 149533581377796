
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/AuthSlice";
import { productReducer } from "./slices/productData";
import { cartReducer } from "./slices/cartSlice";
export const myStore = configureStore({
    reducer:{
        authReducer,
        productReducer,
        cartReducer
    },
});