import React from 'react';
import "./ErrorPage.css";
import ErrorSection from '../../components/errorSection/ErrorSection';

const ErrorPage = () => {
  return (
    <>
      <ErrorSection />
    </>
  );
};

export default ErrorPage;
